import React from "react";
import { Link } from "gatsby"; 
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import image from './img/coupon1bg-77ea895154f1c183eac9da68239987c6.png'
import garageOpenerImage from './img/x87504_267_left_2.png'

// Function to calculate the last day of the current month
const lastDayOfCurrentMonth = () => {
  const date = new Date();
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0); // Get last day of the current month
  return lastDay.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
};

const TanklessWaterHeaterPromotion = () => (
  <>
        <div
          className={`rounded-lg mb-2 shadow bg-nuetral-900  `}
           
        >
          <div className="px-8 pt-6 relative z-1">
            <h4 className="text-brand-600 font-bold text-3xl md:text-4xl">
            $50 OFF

            </h4>
            <h3 className="text-gray-700 font-bold text-lg md:text-xl">
            Any New Garage Door + Opener Combo
            </h3>
            <p className="text-sm text-center text-gray-500 italic">
              *Offer expires {lastDayOfCurrentMonth()}
            </p>
            <Link
              to={'/contact'}
              className="button-active-bhvr my-2 inline-block font-bold tracking-tight text-lg lg:text-xl cursor-pointer transition duration-150 ease-in-out text-brand-600 focus:text-brand-700  focus:outline-none px-5 py-3 rounded-full bg-white shadow font-display"
            >
              Claim Offer
            </Link>
          </div>

          <img 
            src={image}
            className="-mt-72 z-0"
            alt="Garage Door Services Sacramento"
          />  
        </div>
        
        <div
          className={`rounded-lg mb-2 shadow bg-white  `}
          style={{
            background: "linear-gradient(145deg, rgb(215 42 43) 50%, rgb(26 26 26) 50%)",
          }}
        >
          <div className="px-8 pt-6 relative z-1">
            <h4 className="text-white font-bold text-3xl md:text-4xl">
            $10 OFF

            </h4>
            <h3 className="text-white font-bold text-lg md:text-xl">
            On any purchase of $100 or more 
            </h3>   
            <h3 className="text-white font-normal text-sm md:text-base">
              Call Today for an estimate on New Garage Doors
            </h3>
            <Link
              to={'/contact'}
              className="button-active-bhvr my-2 inline-block font-bold tracking-tight text-lg lg:text-xl cursor-pointer transition duration-150 ease-in-out text-brand-600 focus:text-brand-700 focus:outline-none px-5 py-3 rounded-full bg-white shadow font-display"
            >
              Claim Offer
            </Link>
          </div>

          <div className="px-8 py-3">
            <img 
              src={garageOpenerImage}
              className="-my-20 z-0"
              alt="Garage Door Services Sacramento"
            />  
            <p className="text-sm text-center text-gray-500 italic">
              *Offer expires {lastDayOfCurrentMonth()}
            </p>
          </div>
        </div>
  </>
);

export default TanklessWaterHeaterPromotion;
